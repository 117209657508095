slider-component {
  position: relative;
  display: block;
}

@media screen and (max-width: 989px) {
  .no-js slider-component .slider {
    padding-bottom: 3rem;
  }
}

.slider__slide {
  scroll-snap-align: start;
  flex-shrink: 0;
}

@media screen and (max-width: 749px) {
  .slider.slider--mobile {
    position: relative;
    flex-wrap: inherit;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-padding-left: 1rem;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1rem;
  }

  .slider.slider--mobile .slider__slide {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 750px) {
  .slider.slider--tablet-up {
    position: relative;
    flex-wrap: inherit;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-padding-left: 1rem;
    -webkit-overflow-scrolling: touch;
  }

  .slider.slider--tablet-up .slider__slide {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 989px) {
  .slider.slider--tablet {
    position: relative;
    flex-wrap: inherit;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-padding-left: 1rem;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1rem;
  }

  .slider.slider--tablet .slider__slide {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.slider--everywhere {
  position: relative;
  flex-wrap: inherit;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;
}

@media (prefers-reduced-motion) {
  .slider--everywhere {
    scroll-behavior: auto;
  }
}

.slider.slider--everywhere .slider__slide {
  margin-bottom: 0;
  padding-bottom: 0;
  scroll-snap-align: center;
}

/* Scrollbar */

.slider {
  scrollbar-color: rgb(var(--color-foreground)) rgba(var(--color-foreground), 0.04);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider::-webkit-scrollbar {
  height: 0.4rem;
  width: 0.4rem;
  display: none;
}

.no-js .slider {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.no-js .slider::-webkit-scrollbar {
  display: initial;
}

.slider::-webkit-scrollbar-thumb {
  background-color: rgb(var(--color-foreground));
  border-radius: 0.4rem;
  border: 0;
}

.slider::-webkit-scrollbar-track {
  background: rgba(var(--color-foreground), 0.04);
  border-radius: 0.4rem;
}

.slider-counter {
  margin: 0 1.2rem;
  display: flex;
  min-width: 2rem;
}

@media screen and (max-width: 749px) {
  .slider-counter--dots,
  .slider-counter--numbers {
    margin: 0;
  }
}

.slider-counter__link {
  padding: 1rem;
}

@media screen and (max-width: 749px) {
  .slider-counter__link {
    padding: 0.7rem;
  }
}

.slider-counter__link--dots .dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.1rem solid rgba(var(--color-foreground), 0.5);
  padding: 0;
  display: block;
}

.slider-counter__link--active.slider-counter__link--dots .dot {
  background-color: rgb(var(--color-foreground));
}

@media screen and (forced-colors: active) {
  .slider-counter__link--active.slider-counter__link--dots .dot {
    background-color: CanvasText;
  }
}

.slider-counter__link--dots:not(.slider-counter__link--active):hover .dot {
  border-color: rgb(var(--color-foreground));
}

.slider-counter__link--dots .dot,
.slider-counter__link--numbers {
  transition: transform 0.2s ease-in-out;
}

.slider-counter__link--active.slider-counter__link--numbers,
.slider-counter__link--dots:not(.slider-counter__link--active):hover .dot,
.slider-counter__link--numbers:hover {
  transform: scale(1.1);
}

.slider-counter__link--numbers {
  color: rgba(var(--color-foreground), 0.5);
  text-decoration: none;
}

.slider-counter__link--numbers:hover {
  color: rgb(var(--color-foreground));
}

.slider-counter__link--active.slider-counter__link--numbers {
  text-decoration: underline;
  color: rgb(var(--color-foreground));
}

.slider-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 990px) {
  .slider:not(.slider--everywhere) + .slider-buttons {
    display: none;
  }
}

@media screen and (min-width: 750px) {
  .slider--mobile + .slider-buttons {
    display: none;
  }
}

.slider-button {
  color: rgba(var(--color-foreground), 0.75);
  background: transparent;
  border: none;
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-button:not([disabled]):hover {
  color: rgb(var(--color-foreground));
}

.slider-button .icon {
  height: 0.6rem;
}

.slider-button[disabled] .icon {
  color: rgba(var(--color-foreground), 0.3);
  cursor: not-allowed;
}

.slider-button--next .icon {
  transform: rotate(-90deg);
}

.slider-button--prev .icon {
  transform: rotate(90deg);
}

.slider-button--next:not([disabled]):hover .icon {
  transform: rotate(-90deg) scale(1.1);
}

.slider-button--prev:not([disabled]):hover .icon {
  transform: rotate(90deg) scale(1.1);
}
